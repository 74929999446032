
import { defineComponent } from "vue";
import { store } from "@/store";
import { allCourses, getCourse } from "../../utils/courseApi";
import QuestionsComponent from "./QuestionsComponent.vue";
import SubmoduleTab from "./SubmoduleTab.vue";
import MarkdownIt from "markdown-it";
import smartlookClient from "smartlook-client";
import Banner from "@/components/Banner.vue";
import { displayPDFByUser } from "@/utils/getPDFByUser";
import { getAllByOrganisation } from "@training24/course/src/activity";

export default defineComponent({
  components: {
    QuestionsComponent,
    SubmoduleTab,
    Banner,
  },
  data() {
    return {
      activeSubModule: {} as any,
      showQuestion: [],
      course: {} as any,
      iframeWidth: 800,
      md: new MarkdownIt(),
      mobileOverlay: false,
      availableCourses: [],
      courses: [],
    };
  },
  computed: {
    activity: function (): any {
      return store.getters["courseActivity/getActivity"];
    },
    isModuleCompleted: function (): any {
      return store.getters["courseActivity/isCompleted"];
    },
    isDesktop: function () {
      return document.body.offsetWidth > 960;
    },
    users: function () {
      return store.getters["permission/getPermissions"];
    },
    currentUserId: function () {
      return store.getters["getUserId"];
    },
    getCourses: function (): any {
      return this.availableCourses;
    },
  },
  methods: {
    goto: function (path: string) {
      this.$router.push({ path: path });
    },

    getHtml: function (v: string) {
      if (v) {
        return this.md.render(v);
      }
    },
    getIframeWidth: function () {
      const el = document.getElementById("iframe-pptx");
      if (el) {
        return (this.iframeWidth =
          document.getElementById("iframe-pptx").offsetWidth / 1.6601);
      }
      this.iframeWidth = 800;
    },
    isSubmoduleCompleted: function (recordId: string): any {
      return (
        this.activity.passedSubmodules &&
        this.activity.passedSubmodules.length &&
        this.activity.passedSubmodules.some((i: any) => i.id === recordId)
      );
    },
    isQuestionsVisible: function (recordId: string) {
      const res = store.getters["courseActivity/getActivity"];
      return (
        res.showQuestionsInSubmodules &&
        res.showQuestionsInSubmodules.length &&
        res.showQuestionsInSubmodules.some((i: any) => i == recordId)
      );
    },
    markModuleAsCompleted: async function () {
      await store.dispatch("courseActivity/markModuleAsCompleted");
    },
    changeActiveModule: function (module: any) {
      this.activeSubModule = module;
      this.mobileOverlay = true;
    },
    jumpToNextSubmodule: function () {
      if (
        this.course.subModules.every((i: any) => {
          const r =
            this.activity.passedSubmodules &&
            this.activity.passedSubmodules.length &&
            this.activity.passedSubmodules.some((y: any) => {
              return y.id == i.recordId;
            });
          return r;
        })
      ) {
        this.mobileOverlay = false;
        return this.markModuleAsCompleted();
      }

      //Jump to next slide
      const res = this.course.subModules.find(
        (y: any) => !this.isSubmoduleCompleted(y.recordId)
      );
      if (res) {
        this.mobileOverlay = true;
        this.activeSubModule = res;
      }
    },

    reset: async function () {
      if (!this.$route.params.courseId) {
        return;
      }

      this.course = await getCourse(this.$route.params.courseId as string);

      if (!store.getters["organisation/getOrganisationId"]) {
        return;
      }

      await store.dispatch("courseActivity/LOAD", this.$route.params.courseId);

      const t = store.getters["courseActivity/getActivity"].passedSubmodules;
      const active = this.course.subModules.find(
        (s: any) =>
          !store.getters["courseActivity/getActivity"].passedSubmodules.find(
            (p: any) => p.id == s.recordId
          )
      );

      this.activeSubModule = active || this.course.subModules[0];
      smartlookClient.track("viewCourse", {
        courseName: this.course.name,
      });
    },
    points() {
      const name = this.course.name;
      return [
        "Herzlich Willkommen, hier finden Sie alle Lernmodule, aufgeteilt in verschiedene Kapitel.",
        "Am Ende eines jeden Kapitels folgen einige Fragen zum jeweils vorangegangenen Kapitel.",
        "Wenn Sie ein Untermodul durchgearbeitet haben, klicken Sie einfach auf den dazugehörenden Fragenteil.",
        `Sobald Sie alle Kapitel und Fragen erfolgreich abgeschlossen haben, gilt das Thema ${name} als bestanden.`,
        "Wir wünschen viel Vergnügen.",
      ];
    },
    getUserPDF: async function () {
      await this.load();
      await displayPDFByUser(
        this.getCourses,
        this.users.filter((u: any) => (u.userId == this.currentUserId)),
        new Date().getFullYear(),
        store,
        this.courses
      );
    },
    load: async function () {
      this.courses = [];
      const res = await getAllByOrganisation(
        store.getters["organisation/getOrganisationId"],
        new Date().getFullYear(),
        store.getters.getJWT
      );
      res.forEach((item) => {
        this.courses.push(item);
      });
    },
  },
  async mounted() {
    window.addEventListener("orientationchange", this.getIframeWidth);
    window.addEventListener("resize", this.getIframeWidth);

    this.reset();
    this.load();
    this.availableCourses = await allCourses();
  },
});
