<template>
  <div class="banner" v-show="showBanner">
    <div class="banner-points">
      <div class="point" v-for="point in props.points" :key="point">
        <img src="@/assets/images/pointer.png" />
        <span class="point-text">{{ point }}</span>
      </div>
    </div>
    <div class="banner-image">
      <img :src="require('../assets/images/' + image)" />
    </div>
    <div class="cross" @click="toggle">
      <img src="@/assets/images/cross.png" />
    </div>
  </div>
</template>
<script setup>
import { defineProps, ref } from "vue";
const props = defineProps({
  points: {
    type: Array,
    default: () => [],
  },
  image: String,
});

function toggle() {
  showBanner.value = false;
}

let showBanner = ref(true);
</script>
<style lang="scss" scoped>
.banner {
  width: 100%;
  max-width: 100%;
  max-height: auto;
  
  margin: 0 auto;
  background: #f1edf9;
  box-shadow: 2px 3px 28px rgba(126, 129, 234, 0.13);
  border-radius: 25px;
  padding: 30px 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  &-points {
    display: flex;
    flex-direction: column;
    padding-left: 10px;
    padding-right: 20px;
    .point {
      display: flex;
      align-items: center;
      padding-bottom: 7px;
      &-text {
        padding-left: 18px;
        font-weight: 400;
        font-size: 16px;
        line-height: 30px;
        color: $textSecondary;
      }
    }
  }
  .banner-image {
    text-align: right;
    margin-right: 40px;
    img {
      max-width: 80%;
    }
  }
  .cross {
    position: absolute;
    right: 15px;
    top: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 31px;
    height: 31px;
    border-radius: 15.5px;
    background: #f9f6fe;
    border: 1px solid #6c6ff1;
    box-shadow: 2px 2px 14px rgba(108, 111, 241, 0.09);
    &:hover {
      cursor: pointer;
    }
  }
}
@media (max-width: 1024px) {
  .banner {
    flex-direction: column;
    &-points {
      padding-top: 30px;
    }
    &-image {
      margin-top: 10px;
    }
    img {
      max-width: 90%;
    }
  }
}
</style>
