import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-71940c2e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "submodule-tab" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ModuleCard = _resolveComponent("ModuleCard")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.submodules, (module, index) => {
      return (_openBlock(), _createBlock(_component_ModuleCard, {
        class: "submodule",
        key: index,
        module: module,
        isSubmoduleActive: _ctx.isSubmoduleActive(module.recordId),
        isSubmoduleCompleted: _ctx.isSubmoduleCompleted(module.recordId),
        isQuestionsVisible: _ctx.isQuestionsVisible(module.recordId),
        onClick: ($event: any) => (_ctx.changeActiveModule(module)),
        onOnQuestionClick: ($event: any) => {
        _ctx.changeActiveModule(module);
        _ctx.toggleQuestionsVisible(module.recordId, false);
      }
      }, null, 8, ["module", "isSubmoduleActive", "isSubmoduleCompleted", "isQuestionsVisible", "onClick", "onOnQuestionClick"]))
    }), 128))
  ]))
}