import { createRouter, createWebHashHistory, RouteRecordRaw } from 'vue-router'

import HomeView from '../views/HomeView.vue'
import LoginView from '../views/LoginView.vue'
import RegisterView from '../views/RegisterView.vue'
import UserHomeView from '../views/UserHomeView.vue'
import Course from '../views/course/CourseView.vue'
import Courses from '../views/CoursesView.vue'
import CreateOrganisation from '../views/organisation/Create.vue'
import Company from '../views/Company.vue'
import Onboarding from '../views/Onboarding.vue'
import { Auth, Hub } from "aws-amplify";
import { store } from '@/store'

let timeout: ReturnType<typeof setTimeout>
function getUserSesion() {
  return Auth.currentAuthenticatedUser().then((data) => {
    if (data && data.signInUserSession) {
      store.commit('setUserSession', data);
      store.dispatch("getUserDate");
      return data;
    }
  }).catch(() => {
    store.commit('setUserSession', null);
    return null;
  });
}

function refresToken() {
  timeout = setTimeout(() => {
    Auth.currentSession()
      .then((session) => {
        store.commit('setUserSignInToken', session);
      })
      .catch(() => {
        store.commit('setUserSession', null);
        clearTimeout(timeout);
        router.push({ path: '/login' });
      })
  }, 15 * 60 * 1000)
}

Hub.listen("auth", async (data) => {
  if (data.payload.event === "signIn") {
    localStorage.setItem('organisationId', null);
    localStorage.removeItem('organisationId');
    store.commit('setUserSession', data.payload.data);
    const res = await store.dispatch("getUserDate");
    if (res && res.gender && res.first_name && res.last_name) {
      return router.push({ name: 'home' });
    }
    await refresToken();
    return router.push({ name: 'login' });
  }

  if (data.payload.event === "signOut") {
    localStorage.removeItem('organisationId');
    store.commit('setUserSession', null);
    store.commit('setUser', null);
    store.commit('organisation/SET_ORGANISATION', {});
    localStorage.setItem('organisationId', 'undefined');
    clearTimeout(timeout);
    router.push({ path: '/login' });
  }
}
);

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'home',
    redirect: 'user',
    meta: { requiresAuth: true }
  }, {
    path: '/user',
    name: 'userHome',
    redirect: '/course',
    //component: UserHomeView,
    meta: { requiresAuth: true }
  },
  {
    path: '/company',
    name: 'company',
    component: Company,
    meta: { requiresAuth: true }
  }, {
    path: '/company/create',
    name: 'company/create',
    component: CreateOrganisation,
    meta: { requiresAuth: true }
  },
  {
    path: '/course',
    name: 'courses',
    component: Courses,
    meta: { requiresAuth: true }
  },
  {
    path: '/course/:courseId',
    name: 'course',
    component: Course,
    meta: { requiresAuth: true }
  },
  {
    path: '/onboarding',
    name: 'onboarding',
    //component: Onboarding,
    redirect: '/company/create',
    meta: { requiresAuth: true }
  },
  {
    path: '/login',
    name: 'login',
    component: LoginView
  },
  {
    path: '/register',
    name: 'register',
    component: RegisterView
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

router.beforeResolve(async (to, from, next) => {
  const user = await getUserSesion();
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (!user || !store.state.user) {
      const res = await getUserSesion();
      if (!res) {
        return next({
          path: '/login'
        });
      }
    }
    return next()
  }
  if (to.name === 'login' && store.state.user && user) {
    return next({
      path: '/'
    });
  }
  return next()
});

export default router
