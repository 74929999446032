import { useRoute } from "vue-router";
import { show, update } from "@training24/course/src/activity";
import { Activity } from "@training24/course/types/activity";
import { store } from "@/store";

export default {
    namespaced: true,
    state: {
        activity: {} as Activity
    },
    getters: {
        getActivity: (state: any) => {
            return state.activity;
        },
        isCompleted: (state: any) => {
            return state.activity.passedAt;
        },
    },
    actions: {
        async LOAD(context: any, courseId: string) {
            const year = new Date().getFullYear();
            const res = await show(store.getters['organisation/getOrganisationId'], courseId, year, store.getters.getJWT);
            context.commit('SET', res);
        },
        async toggleShowQuestions(context: any, data: { recordId: string, value?: boolean }) {
            context.commit('TOGGLE_SHOW_QUESTION', { recordId: data.recordId, value: data.value });
        },
        async markAsCompleted(context: any, recordId: string) {
            context.commit('SET_AS_COMPLETED', recordId);
            context.dispatch('SUBMIT_TO_BACKEND');
        },
        async markModuleAsCompleted(context: any) {
            context.commit('SET_MODULE_AS_COMPLETED');
            context.dispatch('SUBMIT_TO_BACKEND');
        },
        async SUBMIT_TO_BACKEND(context: any) {
            const payload = {
                passedAt: context.state.activity.passedAt,
                showQuestionsInSubmodules: context.state.activity.showQuestionsInSubmodules,
                passedSubmodules: context.state.activity.passedSubmodules,
            };

            await update(
                store.getters['organisation/getOrganisationId'],
                context.state.activity.courseId,
                context.state.activity.year, payload,
                store.getters.getJWT
            );
        },
    },
    mutations: {
        SET(state: any, data: Activity) {
            state.activity = data;
        },
        SET_AS_COMPLETED(state: any, recordId: string) {
            if (!state.activity.passedSubmodules || !state.activity.passedSubmodules.length) {
                state.activity.passedSubmodules = [];
            }
            state.activity.passedSubmodules = state.activity.passedSubmodules.filter((i: any) => i.id !== recordId);
            state.activity.passedSubmodules.push({
                id: recordId,
                passedAt: Date.now()
            });
        },
        SET_MODULE_AS_COMPLETED(state: any) {
            state.activity.passedAt = Date.now();
        },
        TOGGLE_SHOW_QUESTION(state: any, data: { recordId: string, value?: boolean }) {
            if (state.activity.showQuestionsInSubmodules &&
                state.activity.showQuestionsInSubmodules.length &&
                state.activity.showQuestionsInSubmodules.some((i: any) => i == data.recordId)) {
                state.activity.showQuestionsInSubmodules = state.activity.showQuestionsInSubmodules.filter((i: any) => i != data.recordId);
            } else {
                if (data.value) {
                    state.activity.showQuestionsInSubmodules = [];
                } else {
                    state.activity.showQuestionsInSubmodules.push(data.recordId);
                }
            }
        },
    },
}
