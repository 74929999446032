import { getPDFByUser } from "@training24/course/src/activity";

export const displayPDFByUser = async function (getCourses: any, users: any, selectYear: any, store: any, courses: any) {
    const userHasCourseCompleted = function (userId: string, courseId: string) {
        const t = courses.find(
            (i: any) => i.courseId == courseId && i.userId == userId
        );
        return t && t.passedAt ? t.passedAt : false;
    };

    const res = await getPDFByUser(
        store.getters["organisation/getOrganisationId"],
        selectYear,
        {
            senderCompany: {
                name: 'M E D A . Fortbildungen',
                street: 'Heinrich-Kock-Weg 11',
                postalCode: '22529',
                city: 'Hamburg',
                country: "Deutschland",
            },
            company: {
                name: store.getters["organisation/getOrganisation"].data.company
                    .name,
                street:
                    store.getters["organisation/getOrganisation"].data.company
                        .street +
                    " " +
                    store.getters["organisation/getOrganisation"].data.company
                        .streetNo +
                    " " +
                    store.getters["organisation/getOrganisation"].data.company
                        .addressAddition,
                postalCode:
                    store.getters["organisation/getOrganisation"].data.company
                        .postalCode,
                city: store.getters["organisation/getOrganisation"].data.company
                    .city,
                country: "Deutschland",
            },
            courses: getCourses.map((c: any) => {
                return {
                    course: c.Name.value,
                    users: users
                        .filter((u: any) =>
                            userHasCourseCompleted(u.userId, c.recordId)
                        )
                        .map((u: any) => {
                            return {
                                name: u.userName,
                                date: userHasCourseCompleted(u.userId, c.recordId),
                            };
                        }),
                };
            }),
        },
        store.getters.getJWT
    );
    window.open(res as any, "_blank", "noreferrer");
}