
import { defineComponent } from "vue";
import { store } from "@/store";
import router from "@/router";

export default defineComponent({
  components: {},
  props: {},
  data() {
    return {
      data: {
        company: {},
        contact: {},
        billing: {},
      },
    };
  },
  emits: [],
  computed: {
    organisation: function () {
      return store.getters["organisation/getOrganisation"];
    },
    hasOrganisation: function(){
      return this.organisation && this.organisation.organisationId;
    },
  },
  watch: {
    organisation: {
      handler() {
        if (
          !store.getters["organisation/getOrganisation"] ||
          !store.getters["organisation/getOrganisation"].data
        ) {
          return;
        }
        this.data = {
          company:
            store.getters["organisation/getOrganisation"].data.company || {},
          contact:
            store.getters["organisation/getOrganisation"].data.contact || {},
          billing:
            store.getters["organisation/getOrganisation"].data.billing || {},
        };
      },
      immediate: true,
    },
  },

  methods: {
    save: async function () {
      await store.dispatch("organisation/STORE", this.data);
      router.push({ name: "company" });
    },
    update: async function () {
      await store.dispatch("organisation/UPDATE", this.data);
      router.push({ name: "company" });
    },
  },
});
