import { useRoute } from "vue-router";

export default {
    namespaced: true,
    state: {
    },
    getters: {
        isShrinked: (state: any) => {
            const route = useRoute();
            return route.name === "course";
        }
    },
    actions: {
    },
}
