import { TState } from '@/types/TState';
import { InjectionKey } from 'vue';
import { Store } from 'vuex';
import { store } from '@/store';
import smartlookClient from 'smartlook-client';
import { getAllByUser, store as storeApi, show as showApi, update as updateApi } from '@training24/organisation/src/organisation'
import router from '@/router';

export const key: InjectionKey<Store<TState>> = Symbol()

export default {
  namespaced: true,
  state: {
    organisation: null,
    organisationId: null,
    allOrganisation: [],
    allOrganisationLoaded: false,
    tracked: false
  },
  getters: {
    getOrganisation: (state: any) => {
      return state.organisation;
    },
    getOrganisationId: (state: any) => {
      return state.organisationId;
    },
    getAllOrganisation: (state: any) => {
      if (!state.allOrganisationLoaded) {
        return -1;
      }
      return state.allOrganisation;
    },
    shouldOnboard: (state: any): boolean => {
      if ((!state.organisation || !state.organisation.organisationId) && state.allOrganisationLoaded) {
        return state.allOrganisation.length === 0;
      }
      return false;
    },
  },
  actions: {
    async LOAD(context: any, orgId: string) {
      const organisationId = orgId || localStorage.getItem('organisationId');
      if (!organisationId || organisationId == 'undefined') {
        return context.dispatch('GET_ALL');
      }
      
      context.commit('SET_ORGANISATION_ID', organisationId);
      const organisation = await showApi(organisationId, store.getters.getJWT);
      context.commit('SET_ORGANISATION', organisation);
      store.dispatch('permission/LOAD');
    },
    async GET_ALL(context: any): Promise<any> {
      const res = await getAllByUser(store.getters.getUserId, store.getters.getJWT);
      if (res[0]) {
        context.dispatch('LOAD', res[0].organisationId);
      }
      if(!res.length){
        router.push('/company/create');
      }
      context.commit('SET_ALL_ORGANISATION', res);
    },
    async STORE(context: any, data: any): Promise<any> {
      const res = await storeApi({
        platformId: 'meda',
        name: data.company.name,
        data
      }, store.getters.getJWT);
      context.dispatch('LOAD', res.organisationId);
    },
    async UPDATE(context: any, data: any): Promise<any> {
      const res = await updateApi(context.state.organisation.organisationId, {
        name: data.company.name,
        data
      }, store.getters.getJWT);
      context.dispatch('LOAD', res.organisationId);
    },
  },
  mutations: {
    SET_ORGANISATION(state: any, organisation: any) {
      state.organisation = organisation;
      localStorage.setItem('organisationId', organisation.organisationId);

      if (process.env.VUE_APP_SDK_ENVIRONMENT === "production" && store.getters.getUser && !state.tracked) {
        smartlookClient.init("e0d757c900b02b418aa7d9c6948a35e077ff574f", { region: "eu" });
        smartlookClient.identify(store.getters.getUserId, {
          "name": `${store.getters.getUser.first_name} ${store.getters.getUser.last_name}`,
          "email": store.getters.getEMAIL,
          "organisationId": organisation.organisationId,
          "organisation": organisation.name
        });
        state.tracked = true;
      }
    },
    SET_ORGANISATION_ID(state: any, organisationId: any) {
      state.organisationId = organisationId;
    },
    SET_ALL_ORGANISATION(state: any, organisations: any) {
      state.allOrganisation = organisations;
      state.allOrganisationLoaded = true;
    },
  },
}
