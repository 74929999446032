import { InjectionKey } from 'vue'
import organisation from './organisation'
import permission from './permission';
import sidebar from './sidebar';
import courseActivity from './courseActivity';
import { createStore, useStore as baseUseStore, Store } from 'vuex'

import { TState } from '@/types/TState';
import { show as showProfile, store as storeProfile } from '@training24/user/src/profile'

const API_URL = '/api'

// define injection key
export const key: InjectionKey<Store<TState>> = Symbol()

export const store = createStore<TState>({
  state: {
    userSession: null,
    user: null,
    userDetailsLoaded: false,
  },
  getters: {
    getUser(state) {
      return state.user
    },
    getUserDetailsLoaded(state) {
      return state.userDetailsLoaded
    },
    getUserSession(state) {
      return state.userSession
    },
    getEMAIL(state) {
      return state.userSession.attributes.email;
    },
    getUserId(state) {
      return state.userSession.attributes.sub;
    },
    getUsername(state) {
      return state.userSession.attributes.usernam;
    },
    getJWT(state) {
      return state.userSession ? state.userSession.signInUserSession?.idToken.jwtToken : null;
    }
  },
  mutations: {
    setUserDetailsLoaded(state) {
      state.userDetailsLoaded = true
    },
    setUser(state, user) {
      state.user = user
    },
    setUserSession(state, session) {
      state.userSession = session
    },
    setUserSignInToken(state, session) {
      state.userSession = { ...state.userSession, signInUserSession: session }
    }
  },
  actions: {
    async getUserDate(context) {
      context.dispatch('organisation/LOAD');
      
      if (context.getters.getUser) {
        return context.getters.getUser;
      }
      const result = await showProfile(context.getters.getJWT);
      context.commit('setUserDetailsLoaded');
      if (result.data.last_name && result.data.last_name && result.data.gender) {
        context.commit('setUser', result.data);
        return result.data;
      }
      return;
    },
    async saveUser(context, payload) {
      const result = await storeProfile({
        gender: payload.gender, 
        first_name: payload.first_name, 
        last_name: payload.last_name
      }, context.getters.getJWT);
      context.commit('setUser', result.data)
      return payload
    }
  },
  modules: {
    organisation,
    permission,
    sidebar,
    courseActivity
  }
})

export function useStore() {
  return baseUseStore(key)
}