
import { defineComponent } from "vue";
import { getQuestionsBySubmodule } from "../../utils/courseApi";
import smartlookClient from "smartlook-client";
import { store } from "@/store";

export default defineComponent({
  components: {},
  props: ["activeSubModule", "courseName"],
  data() {
    return {
      actionQuestionIndex: 0,
      choosenAnswer: [],
      choosenAnswerReset: [],
      questions: [],
      showError: false,
    };
  },
  methods: {
    setAnswer: function (answer: any, index: any, evt: any) {
      if (evt.target.type == "radio") {
        this.choosenAnswer = [];
        this.choosenAnswer[index] = answer;
        this.choosenAnswer = this.choosenAnswer.filter((i) => i);
        return;
      }

      if (evt.target.checked) {
        this.choosenAnswer[index] = answer;
      } else {
        delete this.choosenAnswer[index];
        delete this.choosenAnswerReset[index];
        this.choosenAnswer = this.choosenAnswer.filter((i) => i);
      }
    },
    markSubModuleAsComplete: async function () {
      await store.dispatch(
        "courseActivity/markAsCompleted",
        this.activeSubModule.recordId
      );

      smartlookClient.track("markModuleAsComplete", {
        subModule: this.activeSubModule.Name.value,
        courseName: this.courseName,
      });
      this.$emit("jumpToNextSubmodule");
    },
    markModuleAsCompleted: async function () {
      smartlookClient.track("markCourseAsComplete", {
        courseName: this.courseName,
      });
      await store.dispatch("courseActivity/markModuleAsCompleted");
    },
    nextQuestionSlide: function () {
      this.showError = false;
      if (!this.answersTrue) {
        this.choosenAnswer = [];
        this.choosenAnswerReset = [];
        return (this.showError = true);
      }

      if (this.actionQuestionIndex == this.questions.length - 1) {
        return this.markSubModuleAsComplete();
      }

      this.actionQuestionIndex++;
      this.choosenAnswer = [];
      this.choosenAnswerReset = [];
    },
    prevQuestionSlide: function () {
      if (this.actionQuestionIndex <= 0) {
        return;
      }
      this.showError = false;
      this.actionQuestionIndex--;
      this.choosenAnswer = [];
      this.choosenAnswerReset = [];
    },
  },
  computed: {
    actionQuestionSlide: function (): any {
      return this.questions[this.actionQuestionIndex];
    },
    multipeAnswers: function (): any {
      return (
        this.questions[this.actionQuestionIndex].answers.filter(
          (i: any) => i.Correct && i.Correct.value
        ).length > 1
      );
    },
    answersTrue: function (): any {
      const allCorrectAnswers = this.questions[
        this.actionQuestionIndex
      ].answers.filter((i: any) => i.Correct && i.Correct.value);
      return (
        allCorrectAnswers.every((i: any) =>
          this.choosenAnswer.some(
            (y: any) =>
              y.AnswerOption && y.AnswerOption.value == i.AnswerOption.value
          )
        ) &&
        allCorrectAnswers.length == this.choosenAnswer.filter((i) => i).length
      );
    },
  },
  async mounted() {
    this.questions = await getQuestionsBySubmodule(
      this.activeSubModule.recordId
    );
  },
});
