<template>
  <div v-if="!isLoginRoute">
    <Header />
    <div class="wrapper">
      <router-view />
    </div>
  </div>
  <router-view v-else />
</template>
<script setup>
import { computed, ref } from "vue";
import { useRoute } from "vue-router";
import { store } from "@/store";
import Header from "@/components/Header/Header.vue";
import Sidebar from "@/components/Sidebar/Sidebar.vue";
const route = useRoute();
const isLoginRoute = computed(
  () => route.name === "login" || route.name === "register"
);
</script>
<style lang="scss" scoped>
.wrapper {
  max-width: 1280px;
  margin: 130px auto 30px auto;
  padding: 0 10px;
}
@media (max-width: 998px) {
  .wrapper {
    max-width: 100%;
  }
}
@media (min-width: 1400) {
  .wrapper {
    max-width: 1500px;
    margin: 130px auto 30px auto;
  }
}
</style>
