<template>
  <div class="module-card" :class="`${isSubmoduleActive ? 'active' : ''}`">
    <div class="module-card-title-container">
      <div class="title">{{ module.Name.value }}</div>
      <Checkbox :checked="isSubmoduleCompleted" />
    </div>
    <div class="module-card-description"></div>
    <div class="btn-primary " v-if="isSubmoduleActive">
      <span v-if="!isQuestionsVisible" @click.stop.prevent="onQuestionClick"
        >Prüfungsfragen</span
      >
      <span v-if="isQuestionsVisible">Prüfungsfragen</span>
    </div>
  </div>
</template>
<script setup>
import { defineProps, ref, defineEmits } from "vue";
import Checkbox from "@/components/Checkbox.vue";

const props = defineProps({
  module: Object,
  isSubmoduleActive: Boolean,
  isSubmoduleCompleted: Boolean,
  isQuestionsVisible: Boolean,
});
const emit = defineEmits([]);
const onQuestionClick = (e) => {
  emit("onQuestionClick", e);
};
</script>
<style lang="scss" scoped>
.module-card {
  box-sizing: border-box;
  height: 100%;
  background: $white;
  background: #fbf9ff;
  border: 1px solid #fde3f9;
  box-shadow: 2px 3px 25px rgba(235, 145, 222, 0.12);
  border-radius: 16px;
  padding: 15px;
  cursor: pointer;
  &-title-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 5px;
    .title {
      font-weight: 600;
      font-size: 19px;
      line-height: 28px;
      color: #1f1f11;
    }
  }
  &-description {
    font-weight: 400;
    font-size: 14px;
    line-height: 26px;
    color: #545454;
    padding-bottom: 20px;
  }
}
.module-card:hover {
  background: $primary;
  .module-card-title-container {
    .title {
      color: #ffefff;
    }
  }
  .module-card-description {
    color: #ffeffd;
  }
}
.active {
  background: $primary;
  .module-card-title-container {
    .title {
      color: #ffefff;
    }
  }
  .module-card-description {
    color: #ffeffd;
  }
}
</style>
