<template>
  <div class="left-side">
    <div class="left-side-logo">
      <img src="@/assets/images/logo.png" />
    </div>
    <div class="left-side-main-img d-none d-lg-block">
      <img src="@/assets/images/login-left.png" />
    </div>
    <div class="left-side-text d-none d-lg-block">
      <h3>MEDA FORTBILDUNGEN</h3>
      <h6>Die einfachste E-Learning Plattform für die Pflege</h6>
    </div>
  </div>
</template>
<style lang="scss" scoped>
.left-side {
  width: 731px;
  height: 738px;
  background: linear-gradient(141.98deg, #eb91de 0%, #9194ef 99.54%);
  &-logo {
    padding: 30px;
  }
  &-main-img {
    max-width: 100%;
    padding-top: 70px;
    padding-bottom: 78px;
    margin: 0 auto;
    width: fit-content;
    img {
      object-fit: contain;
      width: 100%;
    }
  }
  &-text {
    text-align: center;
    color: $text;
    font-style: normal;
    h3 {
      font-weight: 600;
      font-size: 24px;
      line-height: 37px;
    }
    h6 {
      font-weight: 400;
      font-size: 13px;
      line-height: 26px;
    }
  }
}
@media (max-width: 998px) {
  .left-side {
    width: 100%;
    height: fit-content;
  }
}
</style>
