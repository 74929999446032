<template>
  <div class="header d-flex">
    <div class="header-container d-flex">
      <div class="right-nav">
        <img
          @click="goto('/course')"
          width="200"
          class="logo cursor-pointer"
          src="@/assets/images/logo-color2.png"
        />
      </div>
      <div class="left-nav d-none d-lg-flex">
        <router-link
          class="router-link cursor-pointer me-3"
          to="/course"
          v-if="notOnboarding"
        >
          Verfügbare Kurse
        </router-link>

        <router-link
          class="router-link cursor-pointer"
          v-if="isAdmin && notOnboarding"
          to="/company"
        >
          Administration
        </router-link>

        <a class="btn btn-outline ms-3" href="mailto:mail@meda-fortbildungen.de"
          >Kontakt</a
        >
        <div class="ellipse" @click="logout">
          <vue-feather
            type="log-out"
            size="20px"
            stroke="#EB91DE"
          ></vue-feather>
        </div>
      </div>
      <div
        ref="menu"
        class="burger-btn d-block d-lg-none ms-auto"
        @click="toggleMenu"
      >
        <span class="icon-bar"></span>
        <span class="icon-bar"></span>
        <span class="icon-bar"></span>
      </div>
    </div>
    <!-- Header for mobile screens Start -->
    <Transition name="slide-fade">
      <div v-if="showMenu" class="menu d-flex d-lg-none">
        <div class="d-flex w-100 justify-content-between align-items-center">
          <button
            @click="goto('/course')"
            class="btn-outline btn-small"
            v-if="notOnboarding"
          >
            Verfügbare Kurse
          </button>

          <button
            @click="goto('/company')"
            class="btn-outline btn-small"
            v-if="isAdmin && notOnboarding"
          >
            Administration
          </button>
        </div>
        <div
          class="d-flex w-100 pt-3 justify-content-between align-items-center"
          v-if="store.getters.getUser"
        >
          <div class="user-name">
            {{ store.getters.getUser.first_name }}
            {{ store.getters.getUser.last_name }}
            <a @click="logout" class="cursor-pointer">
              Logout
            </a>
          </div>
          <div
            v-if="store.getters['organisation/getOrganisation']"
            class="user-name"
          >
            {{ store.getters["organisation/getOrganisation"].name }}
          </div>
        </div>
      </div>
    </Transition>
    <!-- Header for mobile screens End -->
  </div>
</template>
<script setup>
import { ref, onMounted, onUnmounted, computed } from "vue";
import { Auth } from "aws-amplify";
import setting from "@/assets/icons/setting.svg";
import { store } from "@/store";
import { useRouter, useRoute } from "vue-router";
import banner from "@/components/ModuleCard.vue";

const route = useRoute();
const router = useRouter();
const menu = ref(null);
const showMenu = ref(false);
const isAdmin = computed(() => store.getters["permission/isAdmin"]);
const notOnboarding = computed(
  () => route.name !== "onboarding" && route.name !== "company/create"
);
function goto(path) {
  router.push({ path: path });
}
function toggleMenu() {
  showMenu.value = !showMenu.value;
}
function logout() {
  localStorage.removeItem("organisationId");
  Auth.signOut();
}
function clickOutSide(event) {
  if (
    menu.value &&
    !(menu.value === event.target || menu.value.contains(event.target))
  ) {
    showMenu.value = false;
  }
}

onMounted(() => {
  document.body.addEventListener("click", clickOutSide);
});

onUnmounted(() => {
  document.body.removeEventListener("click", clickOutSide);
});
</script>
<style lang="scss" scoped>
.header {
  position: fixed;
  top: 0px;
  z-index: 100;
  height: 77px;
  width: 100%;
  background: $white;
  box-shadow: 0.02px -1px 4px 0px rgba(0, 0, 0, 0.6);
  border-radius: 0;
  flex-direction: column;
  &-container {
    height: fit-content;
    padding: 15px 0px;
    display: flex;
    width: 100%;
    max-width: 1280px;
    margin: 0 auto;
    align-items: center;
    .right-nav {
      display: flex;
      align-items: center;
      .divider {
        height: 35px;
        opacity: 0.2;
        border: 1px solid #7e81ea;
        margin: 0px 40px 0px 20px;
      }
    }
    .left-nav {
      margin-left: auto;
      display: flex;
      justify-content: space-between;
      font-weight: 500;
      align-items: center;
      flex-direction: row;
      .facility-setting {
        margin-right: 30px;
        font-size: 17px;
        line-height: 26px;
        text-decoration-line: underline;
        color: $primary;
      }
      .user-name {
        font-size: 18px;
        line-height: 28px;
        color: $textSecondary;
      }
      .company-name {
        font-weight: 400;
        font-size: 16px;
        line-height: 22px;
        color: #656565;
      }
      .router-link {
        color: black;
        text-decoration: none;
        &.router-link-active {
          color: $primary;
        }
        &:hover {
          text-decoration: underline;
        }
      }
      .ellipse {
        margin: 0 10px 0px 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 40px;
        height: 40px;
        background: $white;
        border: 1px solid $primary;
        box-shadow: 2px 3px 28px rgba(150, 152, 237, 0.12);
        border-radius: 27px;
        cursor: pointer;
        &:hover {
          border: 1px solid black;
        }
      }
    }
  }
}
.burger-btn {
  background: $primary;
  height: fit-content;
  width: fit-content;
  padding: 10px 15px;
  border-radius: 14px;
  margin: 0px 10px;
  cursor: pointer;
  .icon-bar {
    border-radius: 10px;
    margin-bottom: 3px;
    height: 4px;
    width: 20px;
    background: $white;
    display: block;
  }
  .icon-bar:nth-child(3) {
    margin-bottom: 0px;
  }
}
.menu {
  z-index: 100;
  padding: 15px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
  background: white;
  width: 100%;
  .user-name {
    font-size: 14px;
    line-height: 22px;
    color: $textSecondary;
    padding-bottom: 2px;
  }
  .company-name {
    font-weight: 400;
    font-size: 12px;
    line-height: 22px;
    color: #656565;
  }
}
.slide-fade-enter-active {
  transition: all 0.3s ease-out;
  opacity: 0.5;
}
.slide-fade-leave-active {
  transition: all 0.3s ease-out;
  opacity: 1;
}
.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateY(-20px);
  opacity: 0;
}

@media (min-width: 1300) {
  .wrapper {
    max-width: 1500px;
  }
}
@media (max-width: 998px) {
  .header {
    &-container {
      max-width: 100%;
    }
    .right-nav {
      .divider {
        margin: 0 10px;
      }
    }
    .left-nav {
      margin-left: auto;
    }
  }
}
</style>
