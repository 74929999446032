<template>
  <div class="card">
    <div class="card-highlight" v-if="isSuccessful"></div>
    <div class="card-img">
      <img
        v-if="
          course.TeaserImage &&
          course.TeaserImage.value &&
          course.TeaserImage.value.length
        "
        :src="`${course.TeaserImage?.value[0].cachedUrl}?password=meda`"
      />
    </div>
    <div class="card-title">{{ course.Name.value }}</div>
    <div class="card-description">
      <span v-html="getClean(course.Description?.value).substring(0, 120)" />
      <span v-if="course.Description.value?.length > 120">...</span>
    </div>
    <div class="card-btn">
      <span class="success" v-if="isSuccessful">Abgeschlossen</span>
      <div class="btn-secondary">
        {{ course.Modules?.value?.length }} Module
      </div>
    </div>
  </div>
</template>
<script setup>
import { defineProps } from "vue";
import MarkdownIt from "markdown-it";
import plainText from "markdown-it-plain-text";

const props = defineProps({
  course: Object,
  isSuccessful: Boolean,
});
const getClean = function (v) {
  const md = new MarkdownIt();
  md.use(plainText);
  md.render(v || '');
  return md.plainText;
};
</script>
<style lang="scss" scoped>
.card {
  box-sizing: border-box;
  width: 360px;
  height: 100%;
  border: 1.2px solid #d3d3d3;
  box-shadow: 2px 3px 26px rgba(126, 129, 234, 0.12);
  border-radius: 15px;
  position: relative;
  padding: 18px 20px;
  &:hover{
    border: 1.2px solid black;
  }
  cursor: pointer;
  &-highlight {
    position: absolute;
    width: 319px;
    height: 7px;
    top: 0px;
    margin: auto;
    background: #1fa738;
    border-radius: 15px;
  }
  &-img {
    width: 318.99px;
    height: 216px;
    border-radius: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      object-fit: contain;
      width: 318.99px;
      height: 216px;
    }
  }
  &-title {
    font-weight: 600;
    font-size: 19px;
    line-height: 26px;
    color: $textSecondary;
    padding-bottom: 7px;
    padding-top: 16px;
  }
  &-description {
    font-weight: 400;
    font-size: 16px;
    line-height: 26px;
    padding-bottom: 20px;
    color: #565656;
  }
  &-btn {
    margin-top: auto;
  }
  .success {
    float: right;
    color: #1fa738;
    font-size: 12px;
    font-weight: 800;
    text-transform: uppercase;
    padding-top: 11px;
  }
}
</style>
