import axios from "axios";
const STAGE = process.env.VUE_APP_SDK_ENVIRONMENT === 'production' ? 'prod' : 'stage';
const BASE_URL = process.env.VUE_APP_SDK_ENVIRONMENT == 'local' ? `https://d2bitsnclopv3i.cloudfront.net/appfMQNFZVnSHTG8y/${STAGE}` : `https://dnfe2wh1fsz65.cloudfront.net/appfMQNFZVnSHTG8y/${STAGE}`;

export const allCourses = async function () {
    return (await axios.get(`${BASE_URL}/Course?password=meda`)).data;
}

export const getCourse = async function (courseId: string) {
    //TODO
    const data = (await axios.get(`${BASE_URL}/Course?password=meda`)).data.find((e: any) => e.recordId == courseId);
    const submodules = (await axios.get(`${BASE_URL}/Module/Course/${courseId}?password=meda`)).data

    if (!data) {
        throw new Error('No Course found')
    }

    return {
        courseId: courseId,
        name: data.Name.value,
        description: data.Description.value,
        subModules: submodules
    }
}

export const getQuestionsBySubmodule = async function (courseId: string) {
    const ps = (await axios.get(`${BASE_URL}/Question/Module/${courseId}?password=meda`))
        .data
        .map(async (q: any) => {
            return {
                ...q,
                answers: (await axios.get(`${BASE_URL}/Answer/Question/${q.recordId}?password=meda`)).data
            };
        });

    const questions = Promise.all(ps);

    if (!questions) {
        throw new Error('No Course found')
    }

    return questions;
}