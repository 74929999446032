
import { defineComponent } from "vue";
import { store } from "@/store";

export default defineComponent({
  emits: ["fieldCreated"],
  data() {
    return {
      password: "",
      error: false,
      errorMsg: "",
      user: {
        email: "",
        userName: "",
        type: "VIEWER",
        sendInivtiationEmail: true,
      },
    };
  },
  methods: {
    save: async function () {
      try {
        this.password = await store.dispatch("permission/ADD", this.user);
      } catch (e: any) {
        this.error = true;

        if (e.response.status == 409) {
          return (this.errorMsg =
            "Es ist ein Fehler aufgetreten: Die E-Mail Adresse existiert bereits.");
        }

        switch (e.response.data.e.code) {
          case "UsernameExistsException":
            this.errorMsg =
              "Es ist ein Fehler aufgetreten: Der Benutzername existiert bereits.";
            break;
          default:
            this.errorMsg =
              "Es ist ein Fehler aufgetreten: " + e.response.data.e.message;
        }
      }
    },
    init: function () {
      this.error = null;
      this.password = "";
      this.errorMsg = "";
      this.user = {
        email: "",
        userName: "",
        type: "VIEWER",
        sendInivtiationEmail: true,
      };
    },
  },
  watch: {},
  mounted() {
    const modal = document.getElementById("inviteUser");
    if (!modal) {
      console.log("something went wrong");
    }
    modal.addEventListener("shown.bs.modal", () => {
      this.init();
    });
  },
});
