
import { store } from "@/store";
import {
  UsersIcon,
  CheckCircleIcon,
  PlayIcon,
  SettingsIcon,
  UserPlusIcon,
  TrashIcon,
} from "@zhuowenli/vue-feather-icons";
import InviteUser from "./modal/InviteUser.vue";
import DeleteUser from "./modal/DeleteUser.vue";
import { defineComponent } from "vue";
import { getAllByOrganisation } from "@training24/course/src/activity";
import dateFormat from "dateformat";
import { allCourses } from "@/utils/courseApi";
import { displayPDFByUser } from "../utils/getPDFByUser";
import { displayPDFByOrg } from "../utils/getPDFByOrg";

export default defineComponent({
  components: {
    InviteUser,
    DeleteUser,
    UsersIcon,
    CheckCircleIcon,
    PlayIcon,
    SettingsIcon,
    UserPlusIcon,

    TrashIcon,
  },
  props: {},
  data() {
    return {
      setSelectedUserEmail: "",
      selectedUserUserId: "",
      courseYears: { 2022: 2022, 2023: 2023 },
      selectYear: new Date().getFullYear(),
      courses: [],
      availableCourses: [],
    };
  },
  emits: [],
  watch: {
    selectYear: function () {
      this.load();
    },
  },
  computed: {
    organisation: function () {
      return store.getters["organisation/getOrganisation"];
    },
    users: function () {
      return store.getters["permission/getPermissions"];
    },
    getCourses: function () {
      return this.availableCourses;
    },
    coursesAmount: function () {
      return this.courses.filter((c: any) =>
        this.getCourses.find((c2: any) => c.recordId === c2.recordId)
      ).length;
    },
  },
  methods: {
    userHasCourseCompleted(userId: string, courseId: string) {
      const t = this.courses.find(
        (i: any) => i.courseId == courseId && i.userId == userId
      );
      return t && t.passedAt ? t.passedAt : false;
    },
    load: async function () {
      this.courses = [];
      const res = await getAllByOrganisation(
        store.getters["organisation/getOrganisationId"],
        this.selectYear,
        store.getters.getJWT
      );
      res.forEach((item) => {
        this.courses.push(item);
      });
    },
    getCompanyPDF: async function () {
      await displayPDFByOrg(
        this.getCourses,
        this.users,
        this.selectYear,
        store,
        this.courses
      );
    },
    getUserPDF: async function (userId: any) {
      await displayPDFByUser(
        this.getCourses,
        this.users.filter((u: any) => (u.userId == userId)),
        this.selectYear,
        store,
        this.courses
      );
    },
  },
  async mounted() {
    this.availableCourses = await allCourses();
    this.load();
  },
});
