
import { defineComponent } from "vue";
import { store } from "@/store";

export default defineComponent({
  props: {
    userId: {
      type: String,
    },
  },
  emits: [""],
  data() {
    return {};
  },
  methods: {
    deleteUser: function () {
      store.dispatch("permission/REMOVE", this.userId);
    },
  },
  watch: {},
});
