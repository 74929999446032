
import { defineComponent } from "vue";
import { allCourses } from "../utils/courseApi";
import MarkdownIt from "markdown-it";
import plainText from "markdown-it-plain-text";
import { getAll } from "@training24/course/src/activity";
import { store } from "@/store";
import Banner from "@/components/Banner.vue";
import CourseCard from "@/components/CourseCard.vue";

export default defineComponent({
  components: {
    Banner,
    CourseCard,
  },
  data() {
    return {
      coursesGrouped: {} as any,
      md: new MarkdownIt(),
      courseActivity: [],
    };
  },
  computed: {
    loggedIn: function () {
      return (
        store.getters.getJWT + store.getters["organisation/getOrganisationId"]
      );
    },
  },
  watch: {
    loggedIn: function () {
      this.init();
    },
  },
  methods: {
    init: async function () {
      await this.load();
      const res = await allCourses();
      res.forEach((course: any) => {
        course.Category?.value?.forEach((cat: any) => {
          if (!this.coursesGrouped[cat.name]) {
            this.coursesGrouped[cat.name] = [];
          }
          this.coursesGrouped[cat.name].push(course);
        });
      });
    },
    isSuccessful: function (recordId: string) {
      console.log(this.courseActivity, 'this.courseActivity',  new Date().getFullYear());
      return this.courseActivity.some((r) => r.courseId === recordId && r.passedAt && r.year == new Date().getFullYear());
    },
    load: async function () {
      this.courseActivity = await getAll(
        store.getters["organisation/getOrganisationId"],
        store.getters.getJWT
      );
    },
    getClean: function (v: string) {
      const md = new MarkdownIt();
      md.use(plainText);
      md.render(v);

      return (md as any).plainText;
    },
    points() {
      return [
        "Hier finden Sie alle für Sie freigegebenen Kurse.",
        "Klicken Sie einfach auf das zu bearbeitende Thema, um dieses zu starten.",
        "Sie können jedes Lernmodul jederzeit pausieren und zu einem späteren Zeitpunkt fortsetzen.",
        "Klicken Sie jetzt auf das gewünschte Thema.",
      ];
    },
  },
  async mounted() {
    this.init();
  },
});
