
import { defineComponent } from "vue";
import { store } from "@/store";
// import { CheckCircleIcon } from "@zhuowenli/vue-feather-icons";
import smartlookClient from "smartlook-client";
import ModuleCard from "@/components/ModuleCard.vue";

export default defineComponent({
  components: {
    // CheckCircleIcon,
    ModuleCard,
  },
  props: ["submodules", "activeSubModule", "courseName"],
  data() {
    return {};
  },
  computed: {
    activity: function (): any {
      return store.getters["courseActivity/getActivity"];
    },
  },
  methods: {
    changeActiveModule: function (module: any) {
      this.$emit("changeActiveModule", module);
      this.toggleQuestionsVisible(module.recordId, true);
      smartlookClient.track("changeToModule", {
        subModule: module.Name.value,
        courseName: this.courseName,
      });
    },
    isSubmoduleCompleted: function (recordId: string): any {
      return (
        this.activity.passedSubmodules &&
        this.activity.passedSubmodules.length &&
        this.activity.passedSubmodules.some((i: any) => i.id === recordId)
      );
    },
    isSubmoduleActive: function (recordId: string): any {
      return this.activeSubModule.recordId == recordId;
    },
    isQuestionsVisible: function (recordId: string) {
      const res = store.getters["courseActivity/getActivity"];
      return (
        res.showQuestionsInSubmodules &&
        res.showQuestionsInSubmodules.length &&
        res.showQuestionsInSubmodules.some((i: any) => i == recordId)
      );
    },
    toggleQuestionsVisible: function (recordId: string, value: boolean) {
      return store.dispatch("courseActivity/toggleShowQuestions", {
        recordId: recordId,
        value: value,
      });
    },
  },
});
