import { TState } from '@/types/TState';
import { InjectionKey } from 'vue';
import { Store } from 'vuex';
import { store } from '@/store';

import { getAll as getAllApi, store as storeApi, destroy as destroyApi } from '@training24/organisation/src/permission'
import { IPermissionTypes } from '@training24/organisation/types/permission';

export const key: InjectionKey<Store<TState>> = Symbol()

export default {
  namespaced: true,
  state: {
    permissions: [],
  },
  getters: {
    getPermissions: (state: any) => {
      return state.permissions;
    },
    isAdmin: (state: any) => {
      return state.permissions && state.permissions.some((p:any) => p.userId === store.getters.getUserId && p.type === 'OWNER');
    }
  },
  actions: {
    async LOAD(context: any) {
      const res = await getAllApi(store.getters['organisation/getOrganisation'].organisationId, store.getters.getJWT);
      context.commit('SET_PERMISSIONS', res);
    },

    async ADD(context: any, data: {
      email: string,
      userName: string, 
      type: IPermissionTypes,
      sendInivtiationEmail: boolean
    }): Promise<any> {
      const res = await storeApi(store.getters['organisation/getOrganisation'].organisationId, {
        email: data.email,
        userName: data.userName.toLowerCase().replace(' ', '-'),
        sendInivtiationEmail: data.sendInivtiationEmail,
        type: data.type,
      }, store.getters.getJWT);

      //TODO FASTEN DO NOT DO A FULL RELOAD
      context.dispatch('LOAD');
      return res.password;
    },
    async REMOVE(context: any, email: string): Promise<any> {
      const res = await destroyApi(store.getters['organisation/getOrganisation'].organisationId, email, store.getters.getJWT);
      //TODO FASTEN DO NOT DO A FULL RELOAD
      context.dispatch('LOAD');
    },
  },
  mutations: {
    SET_PERMISSIONS(state: any, res: any) {
      state.permissions = res;
    },
  },
}
